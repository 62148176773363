import React, { useRef, useEffect, useState } from 'react';
import { useWtrSelector } from 'redux/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getSearchFilterTags } from 'redux/modules/search/selectors';

import loadable from '@loadable/component';
import { useIntersectionObserver } from 'hooks/use-intersection-observer';
import useScrollDirection, { UP } from 'hooks/use-scroll-direction';
import { scrollIntoViewAndFocusElement } from 'utils/scroll';

import styles from 'components/Product/Refinements/Refinements.scss';

const ProductsSort = loadable(() => import('components/Search/SortBy'));
const ProductsFilterForm = loadable(() => import('components/Search/Filters/FilterForm'));

const Refinements = ({ hasRefinements, hideCategoryFilter, sortOptions, isMultiSearch }) => {
  const staticRefinementsRef = useRef(null);
  const isScrollingUp = useScrollDirection() === UP;

  const [filtersRendered, setFiltersRendered] = useState(null);
  const { isIntersecting: filtersAreVisible, boundingRect } =
    useIntersectionObserver(filtersRendered);

  const hasFilterTags = useWtrSelector(getSearchFilterTags).length > 0;

  useEffect(() => {
    if (staticRefinementsRef.current) {
      setFiltersRendered(staticRefinementsRef.current);
    }
  }, []);

  if (!hasRefinements && !hasFilterTags) {
    return null;
  }

  const isBelowFilters = boundingRect ? boundingRect.top < 0 : false;

  const showStickyFilters = filtersRendered
    ? !filtersAreVisible && isScrollingUp && isBelowFilters && !isMultiSearch
    : false;

  const scrollToStaticFilters = () => {
    scrollIntoViewAndFocusElement(staticRefinementsRef.current, { top: 0, topOffset: 60 });
  };

  return (
    <div className={styles.wrapperNew} data-testid="product-refinements" id="product-refinements">
      <div
        className={classNames(styles.stickyRefinements, {
          [styles.stickyEnabled]: showStickyFilters,
        })}
        data-testid="sticky-refinements"
      >
        <ProductsFilterForm
          hideCategoryFilter={hideCategoryFilter}
          onChange={scrollToStaticFilters}
          stickyFilter
          hideOfferType
        />
        <ProductsSort onChange={scrollToStaticFilters} sortOptions={sortOptions} stickyFilter />
      </div>
      <div
        className={styles.refinements}
        ref={staticRefinementsRef}
        data-testid="static-refinements"
      >
        <ProductsFilterForm
          hideCategoryFilter={hideCategoryFilter}
          hideOfferType
          forceOfferToggle={hasFilterTags && !hasRefinements}
        />
        <ProductsSort sortOptions={sortOptions} />
      </div>
    </div>
  );
};

Refinements.propTypes = {
  hasRefinements: PropTypes.bool,
  isMultiSearch: PropTypes.bool,
  hideCategoryFilter: PropTypes.bool,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, order: PropTypes.number, value: PropTypes.string }),
  ).isRequired,
};

Refinements.defaultProps = {
  hasRefinements: false,
  hideCategoryFilter: false,
  isMultiSearch: false,
};

Refinements.displayName = 'Refinements';

export default Refinements;
