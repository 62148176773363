import { connect } from 'react-redux';

import { fetchMoreComponentsAndProducts } from 'redux/modules/search-and-browse/actions/fetch-more-components-and-products';
import { getDisplayedProducts } from 'redux/modules/search-and-browse/selectors/get-displayed-products';
import {
  getGridItems,
  getUniqueSponsoredItemsCount,
} from 'redux/modules/search-and-browse/selectors';

import LoadMoreButton from './LoadMoreButton';

export default connect(
  state => ({
    fetchMoreStartIndex: getDisplayedProducts(state) - getUniqueSponsoredItemsCount(state),
    hasMore: state.searchAndBrowse.hasMore,
    loading: state.searchAndBrowse.loadingMore,
    nextAutoFocusIndex: getGridItems(state).length,
    searchType: state.searchAndBrowse.request?.name,
  }),
  {
    fetchMore: fetchMoreComponentsAndProducts,
  },
)(LoadMoreButton);
