import { useState, useEffect } from 'react';

export const useIntersectionObserver = (element: HTMLElement) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (!element) {
      return () => {};
    }

    const observer = new IntersectionObserver(
      ([observedElement]) => {
        setIsIntersecting(observedElement.isIntersecting);
        setBoundingRect(observedElement.boundingClientRect);
      },
      { threshold: [0] },
    );

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [element]);

  return {
    isIntersecting,
    boundingRect,
  };
};
